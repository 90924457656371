<template>
  <v-container grid-list-xl text-xs-center>
    <v-layout row wrap align-center>
      <v-flex xs12>
        <v-list-item-title class="headline">Dashboard</v-list-item-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-card
                class="mt-4 mx-auto"
              >
                <v-sheet
                  class="v-sheet--offset mx-auto"
                  color="white"
                  elevation="12"
                  max-width="calc(100% - 32px)"
                >
                  <v-skeleton-loader
                    v-if="!loaded1"
                      class="mx-auto"
                      type="image"
                    ></v-skeleton-loader>
                  <bar-chart
                    v-else
                    :chartdata="barchartdata"
                    :options="options"/>
                </v-sheet>

                <v-card-text class="pt-0">
                  <div class="title font-weight-light mb-2">Homework Submission vs Marked Homework</div>
                  <div class="subheading font-weight-light grey--text">{{title}}</div>
                  <v-divider class="my-2"></v-divider>
                  <v-icon
                    class="mr-2"
                    small
                  >
                    mdi-clock
                  </v-icon>
                  <span class="caption grey--text font-weight-light">updated on {{ new Date() | formatDateTime }}</span>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-card
                class="mt-4 mx-auto"
              >
                <v-sheet
                  class="v-sheet--offset mx-auto"
                  color="white"
                  elevation="12"
                  max-width="calc(100% - 32px)"
                >
                  <v-skeleton-loader
                    v-if="!loaded2"
                      class="mx-auto"
                      type="image"
                    ></v-skeleton-loader>
                  <line-chart
                    v-else
                    :chartdata="linechartdataHS"
                    :options="options"/>
                </v-sheet>

                <v-card-text class="pt-0">
                  <div class="title font-weight-light mb-2">Homework Submission (Last 5 Days)</div>
                  <div class="subheading font-weight-light grey--text">{{title}}</div>
                  <v-divider class="my-2"></v-divider>
                  <v-icon
                    class="mr-2"
                    small
                  >
                    mdi-clock
                  </v-icon>
                  <span class="caption grey--text font-weight-light">updated on {{ new Date() | formatDateTime }}</span>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-card
                class="mt-4 mx-auto"
              >
                <v-sheet
                  class="v-sheet--offset mx-auto"
                  color="white"
                  elevation="12"
                  max-width="calc(100% - 32px)"
                >
                  <v-skeleton-loader
                    v-if="!loaded3"
                      class="mx-auto"
                      type="image"
                    ></v-skeleton-loader>
                  <line-chart
                    v-else
                    :chartdata="linechartdataHM"
                    :options="options"/>
                </v-sheet>

                <v-card-text class="pt-0">
                  <div class="title font-weight-light mb-2">Marked Homework (Last 5 Days)</div>
                  <div class="subheading font-weight-light grey--text">{{title}}</div>
                  <v-divider class="my-2"></v-divider>
                  <v-icon
                    class="mr-2"
                    small
                  >
                    mdi-clock
                  </v-icon>
                  <span class="caption grey--text font-weight-light">updated on {{ new Date() | formatDateTime }}</span>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-flex> 
    </v-layout>
  </v-container> 
</template>

<script>
import BarChart from "@/components/BarChart";
import LineChart from "@/components/LineChart";
import { httpClient } from '../services/http-client';
import { mapGetters } from "vuex";

export default {
  name: 'Dashboard',
  components: {
    BarChart,
    LineChart,
  },
  data () {
    return {
      loaded1: false,
      loaded2: false,
      loaded3: false,
      barchartdata: null,
      linechartdataHS: null,
      linechartdataHM: null,
      options: {
        responsive: true,
        maintainAspectRatio: false
      },
      title: "By Region",
    }
  },
  computed: {
    ...mapGetters(["isAuthenticated", "isAdmin", "isStateDirector", "isCampusDirector"])
  },
  async mounted () {
    var homeworkByRegionURL = "";
    var last5DaysSubmissionURL = "";
    var last5DaysMarkedURL = ""; 
    if (this.isAdmin) {
      homeworkByRegionURL = "/statistic/homeworkByAllRegions";
      last5DaysSubmissionURL = "/statistic/last5DaysSubmissionByAllRegions";
      last5DaysMarkedURL = "/statistic/last5DaysMarkedByAllRegions";
    }
    else if (this.isStateDirector) {
      homeworkByRegionURL = "/statistic/homeworkByRegion";
      last5DaysSubmissionURL = "/statistic/last5DaysSubmissionByRegion";
      last5DaysMarkedURL = "/statistic/last5DaysMarkedByRegion";
    }
    else if (this.isCampusDirector) {
      homeworkByRegionURL = "statistic/homeworkByCampus";
      last5DaysSubmissionURL = "/statistic/last5DaysSubmissionByCampus";
      last5DaysMarkedURL = "/statistic/last5DaysMarkedByCampus";
      this.title = "By Campus";
    }
    const borderColors = ['#29B6F6', '#9575CD', '#E91E63', '#009688', '#FFEB3B', '#FF9800', '#795548']
    
    this.loaded1 = false;
    try {
      const response = await httpClient.get(homeworkByRegionURL);
      this.barchartdata = {
        labels: response.data.labels,
        datasets : [
          {
            label: 'Homework Submission',
            backgroundColor: '#29B6F6',
            data: response.data.totalSubmissionByRegion
          },
          {
            label: 'Marked Homework',
            backgroundColor: '#9575CD',
            data: response.data.totalMarkedByRegion
          },
        ]
      };
    } catch (e) {
      console.error(e)
    }
    this.loaded1 = true

    this.loaded2 = false;
    try {  
      const responseHS = await httpClient.get(last5DaysSubmissionURL);
      this.linechartdataHS = {
        labels: responseHS.data.labels,
        datasets: responseHS.data.datasets.map((d, index) => {
          d.borderColor = borderColors[index];
          d.fill = false;
          return d;
        }),
      };       
    } catch (e) {
      console.error(e)
    }
    this.loaded2 = true;
    
    this.loaded3 = false;
    try {
      const responseMarked = await httpClient.get(last5DaysMarkedURL);
      this.linechartdataHM = {
        labels: responseMarked.data.labels,
        datasets: responseMarked.data.datasets.map((d, index) => {
          d.borderColor = borderColors[index];
          d.fill = false;
          return d;
        }),
      };   
    } catch (e) {
      console.error(e)
    }
    this.loaded3 = true;

  },
}
</script>

<style>
  .v-sheet--offset {
    top: -24px;
    position: relative;
  }
</style>